'use client';
import { useEffect, useState } from 'react';

import { useMe, USER_ROLE } from './useMe';
import axios, { AxiosResponse } from 'axios';

export interface IUserCore {
  id: number;
  email: string;
}
export interface IUser {
  id: number;
  userId: number;
  role: USER_ROLE;
  user: IUserCore;
}

export interface ICreateRole {
  publicKey: string;
  privateKey: string;
  rePrivateKey: string;
  role: USER_ROLE;
}

export const useUser = () => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [jwt, setJwt] = useState<string | undefined>();
  const [refreshToken, setRefreshToken] = useState<string | undefined>();
  const [auth, setAuth] = useState<boolean>(false);

  const logout = () => {
    setJwt(undefined);
    localStorage.clear();
    setAuth(false);
  };

  const { fetchMe, me, error } = useMe(logout);
  const [users, setUsers] = useState<IUser[] | undefined>(undefined);
  const [userEmails, setUserEmails] = useState<IUserCore[] | undefined>(
    undefined,
  );

  let fetchRefreshToken: any = null;

  if (refreshToken) {
    fetchRefreshToken = async (): Promise<{
      accessToken: string;
      refreshToken: string;
    }> => {
      return axios.post(`${apiUrl}/auth/refresh-token`, {
        refreshToken,
      });
    };
  }

  const getUsers = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/roles/`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setUsers(data);
    } catch (e) {}
  };

  const getUserEmails = async () => {
    try {
      const { data } = await axios.get(`${apiUrl}/users/`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      setUserEmails(data.items);
    } catch (e) {
      console.log(e);
    }
  };

  const deleteUserRole = async (userId: number) => {
    try {
      await axios.delete(`${apiUrl}/auth/${userId}`, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      await getUsers();
    } catch (e) {
      console.log(e);
    }
  };

  const createUserRole = async (data: ICreateRole) => {
    try {
      await axios.post(`${apiUrl}/auth/register`, data, {
        headers: {
          Authorization: `Bearer ${jwt}`,
        },
      });
      await getUsers();
    } catch (e) {
      console.log(e);
    }
  };

  const authenticate = async (publicKey: string, privateKey: string) => {
    try {
      const { data }: AxiosResponse = await axios.post(`${apiUrl}/auth/login`, {
        publicKey: publicKey,
        privateKey: privateKey,
      });
      if (data.accessToken) {
        localStorage.setItem('jwt', data.accessToken);
        localStorage.setItem('refreshToken', data.refreshToken);
        await fetchMe(data.accessToken);
        setJwt(data.accessToken);
        setAuth(true);
      } else {
        setAuth(false);
      }
    } catch (error) {
      localStorage.clear();
      console.log(error);
    }
  };

  useEffect(() => {
    const savedJwt = localStorage.getItem('jwt');
    const savedRefreshToken = localStorage.getItem('refreshToken');

    if (savedJwt && savedRefreshToken) {
      setJwt(savedJwt);
      setRefreshToken(savedRefreshToken);
    } else {
      localStorage.clear();
    }
  }, []);

  useEffect(() => {
    if (jwt && !me && !auth) {
      fetchMe(jwt)
        .catch(() =>
          fetchRefreshToken(refreshToken!)
            .then((value: any) => {
              if (value) {
                setJwt(value.accessToken);
                localStorage.setItem('jwt', JSON.stringify(value.accessToken));
                localStorage.setItem(
                  'refreshToken',
                  JSON.stringify(value.refreshToken),
                );
                return fetchMe(jwt)
                  .then(() => {
                    setAuth(true);
                  })
                  .catch(() => {
                    logout();
                  });
              } else {
                logout();
                localStorage.clear();
              }
            })
            .catch((e: any) => {
              console.log(e);
              logout();
            }),
        )
        .then(() => {
          setAuth(true);
        })
        .catch(() => {
          logout();
        });
    }
  }, [jwt, auth, me]);

  useEffect(() => {
    if (error) {
      localStorage.clear();
    }
  }, [error]);

  return {
    auth,
    jwt,
    setJwt,
    setAuth,
    authenticate,
    me,
    getUsers,
    users,
    getUserEmails,
    userEmails,
    createUserRole,
    deleteUserRole,
    logout,
  };
};
