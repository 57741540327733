import { useEffect, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { QUESTION_TYPE, TQuestion } from '../components/Admin/Admin';

export interface ILink {
  id: string;
  estimates?: IEstimate[];
  forOrganizationId: string;
  organization: string;
  vertical?: IVertical;
  forOrganization: IOrganization;
  managerId?: string;
  manager?: IManager;
  isMultiplyResponse: boolean;
  createAt: Date;
  updateAt: Date;
}

export interface IQuestionToVerticals {
  id: string;
  verticalId: number;
  questionId: string;
  vertical: IVertical;
  question: IQuestion;
}
export interface IQuestion {
  id: string;
  text: string;
  order?: number;
  questionToVerticals?: IQuestionToVerticals[];
  responseType: QUESTION_TYPE;
}

export interface ICreateQuestion {
  text: string;
  responseType: QUESTION_TYPE;
}
export interface IUpdateLink {
  forOrganizationId: string;
  isMultiplyResponse: boolean;
  managerId: string;
  verticalId: number;
}
export interface IEstimate {
  id?: string;
  estimate: number;
  linkId: string;
  comment: string | null;
  questionId?: string;
  link?: ILink;
  createAt?: Date;
  updateAt?: Date;
}

export interface ICreateAnswer {
  text?: string;
  estimate?: number;
  questionId: string;
  linkId: string;
}
export interface IOrganization {
  id: string;
  title: string;
  managers: IManager[];
}

export interface IManager {
  id: number;
  name: string;
  surname: string;
  middlename?: string;
  email: string;
}

export interface IVertical {
  id: number;
  title: string;
  questionToVerticals?: IQuestionToVerticals[];
}

export function useLink(linkId?: string) {
  const apiUrl = process.env.REACT_APP_API_URL;
  //process..env.REACT_APP_API_URL;
  const [link, setLink] = useState<ILink | undefined>(undefined);
  const [links, setLinks] = useState<ILink[] | undefined>(undefined);
  const [error, setError] = useState<any | undefined>(undefined);
  const [organizations, setOrganizations] = useState<
    IOrganization[] | undefined
  >(undefined);
  const [verticals, setVerticals] = useState<IVertical[] | undefined>(
    undefined,
  );
  const [questions, setQuestions] = useState<IQuestion[] | undefined>(
    undefined,
  );
  const getLink = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(
        `${apiUrl}/link/${linkId}`,
      );
      if (!data.status) {
        setLink(data);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const getLinks = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(`${apiUrl}/link/`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
      if (data) {
        setLinks(data.items);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
      localStorage.clear();
    }
  };

  const updateLink = async (id: string, data: IUpdateLink) => {
    try {
      await axios.patch(`${apiUrl}/link/${id}`, data, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
      await getLinks();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const createLink = async (data: IUpdateLink) => {
    try {
      await axios.post(`${apiUrl}/link/`, data, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
      await getLinks();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const deleteLink = async (linkId: string): Promise<boolean> => {
    try {
      await axios.delete(`${apiUrl}/link/${linkId}`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
      await getLinks();
      return true;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const createEstimate = async (linkId: string, estimate: IEstimate) => {
    try {
      await axios.post(`${apiUrl}/estimate`, estimate, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createEstimates = async (estimate: IEstimate[]) => {
    try {
      await axios.post(`${apiUrl}/estimate/many`, estimate, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const createAnswer = async (answer: ICreateAnswer) => {
    try {
      await axios.post(`${apiUrl}/answers`, answer, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganizations = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(`${apiUrl}/client/`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
      if (data) {
        setOrganizations(data.items);
      } else {
        setError(data);
        localStorage.clear();
      }
    } catch (error) {
      console.log(error);
      setError(error);
    }
  };

  const getVerticals = async () => {
    try {
      const { data }: AxiosResponse = await axios.get(`${apiUrl}/verticals/`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });

      if (data) {
        setVerticals(data.items);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const updateQuestion = async (id: string, data: TQuestion) => {
    try {
      await axios.patch(`${apiUrl}/questions/${id}`, data, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
      await getQuestions();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const getQuestions = async (verticalId?: number) => {
    try {
      const { data }: AxiosResponse = await axios.get(
        `${apiUrl}/questions/${verticalId ? verticalId : ''}`,
        {
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        },
      );

      if (data) {
        setQuestions(data.items);
      } else {
        setError(data);
      }
    } catch (error) {
      setError(error);
    }
  };

  const createQuestion = async (data: ICreateQuestion) => {
    try {
      await axios.post(`${apiUrl}/questions/`, data, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
      await getQuestions();
    } catch (error) {
      setError(error);
      console.log(error);
    }
  };

  const deleteQuestion = async (questionId: string): Promise<boolean> => {
    try {
      await axios.delete(`${apiUrl}/questions/${questionId}`, {
        headers: {
          authorization: 'Bearer ' + localStorage.getItem('jwt'),
        },
      });
      await getQuestions();
      return true;
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const insertQuestionToVertical = async (
    verticalId: number,
    questionId: string,
  ): Promise<any> => {
    try {
      await axios.post(
        `${apiUrl}/question-to-verticals/`,
        {
          verticalId,
          questionId,
        },
        {
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        },
      );
      await getQuestions();
    } catch (error) {
      setError(error);
      return false;
    }
  };

  const removeQuestionToVertical = async (
    verticalId: number,
    questionId: string,
  ): Promise<any> => {
    try {
      await axios.delete(
        `${apiUrl}/question-to-verticals/${questionId}/${verticalId}`,
        {
          headers: {
            authorization: 'Bearer ' + localStorage.getItem('jwt'),
          },
        },
      );
      await getQuestions();
    } catch (error) {
      setError(error);
      return false;
    }
  };

  useEffect(() => {
    if (linkId && !error && !link) {
      getLink();
    }
  }, [linkId, error, link]);

  return {
    link,
    links,
    createEstimate,
    createEstimates,
    getLink,
    getLinks,
    organizations,
    getOrganizations,
    updateLink,
    createLink,
    deleteLink,
    error,
    setError,
    createQuestion,
    deleteQuestion,
    createAnswer,
    getVerticals,
    insertQuestionToVertical,
    removeQuestionToVertical,
    verticals,
    questions,
    getQuestions,
    updateQuestion,
  };
}
