import { useState } from 'react';
import axios from 'axios';

export enum USER_ROLE {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  DIRECTOR = 'DIRECTOR',
}

export interface IMe {
  id: string;
  role: {
    role: USER_ROLE;
  };
  userId: number;
}

export const useMe = (logout: () => void) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<any>();
  const [me, setMe] = useState<IMe | undefined>(undefined);

  const fetchMeRequest = async (jwtToken: string) => {
    const response = await axios(`${process.env.REACT_APP_API_URL}/auth/me`, {
      headers: {
        authorization: `Bearer ${jwtToken}`,
      },
    });
    setMe(response.data);
  };

  const fetchMe = async (jwt: string) => {
    setLoading(true);
    try {
      await fetchMeRequest(jwt);
      setLoading(false);
    } catch (e) {
      setError(e);
      logout();
      setLoading(false);
    }
  };

  return { fetchMe, loading, error, me, setMe };
};
