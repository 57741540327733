import React, { Children, ReactNode } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { EffectFade } from 'swiper/modules';

export interface ICarouselProps {
  children: ReactNode;
  swiperRef?: any;
}
export const Carousel = ({ children, swiperRef }: ICarouselProps) => {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{ width: '100%' }}>
        <Swiper
          ref={swiperRef}
          slidesPerView={1}
          pagination={false}
          allowSlidePrev={false}
          allowSlideNext={true}
          allowTouchMove={false}
          effect={'slide'}
          modules={[EffectFade]}
        >
          {Children.map(children, (child) => (
            <SwiperSlide>{child}</SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
