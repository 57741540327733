import { Button, Card, Checkbox, Form, FormProps, Input, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { IManager, useLink } from '../../hooks/useLink';
import { Item, LinksTable } from '../LinksTable/LinksTable';
import { useUser } from '../../hooks/useUser';
import { QuestionsTable } from '../QuestionsTable/QuestionsTable';
import TextArea from 'antd/es/input/TextArea';
import { USER_ROLE } from '../../hooks/useMe';
import { UsersTable } from '../UsersTable/UsersTable';

type FieldType = {
  forOrganizationId?: string;
  isMultiplyResponse?: boolean;
  managerId?: string;
  verticalId?: number;
};

export enum QUESTION_TYPE {
  ESTIMATE = 'ESTIMATE',
  COMMENT = 'COMMENT',
}

export type TQuestion = {
  text: string;
  order?: number | null;
  verticals?: number[];
  responseType: QUESTION_TYPE;
};

export type TUser = {
  publicKey: string;
  role: USER_ROLE;
  privateKey: string;
  rePrivateKey: string;
};

type LoginType = {
  publicKey?: string;
  privateKey?: string;
};

export const Admin = () => {
  const {
    auth,
    authenticate,
    jwt,
    setAuth,
    me,
    getUsers,
    users,
    userEmails,
    getUserEmails,
    createUserRole,
    deleteUserRole,
    logout,
  } = useUser();

  const {
    links,
    getLinks,
    getOrganizations,
    organizations,
    createLink,
    updateLink,
    deleteLink,
    createQuestion,
    deleteQuestion,
    error,
    getVerticals,
    insertQuestionToVertical,
    removeQuestionToVertical,
    verticals,
    questions,
    getQuestions,
    updateQuestion,
    setError,
  } = useLink();

  const [managers, setManagers] = useState<IManager[] | undefined>([]);
  const [isMultiplyChecked, setIsMultiplyChecked] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      if (me?.role.role === USER_ROLE.ADMIN) {
        await getUsers();
        await getUserEmails();
      }
      await getLinks();
      await getOrganizations();
      await getVerticals();
      await getQuestions();
    };
    if (jwt && me && auth) {
      fetchData();
    }
  }, [me]);

  const onFinish: FormProps<LoginType>['onFinish'] = async (values) => {
    await authenticate(values.publicKey!, values.privateKey!);
  };
  return (
    <div
      style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        gap: '30px',
        overflow: 'scroll',
      }}
    >
      {!auth || !me ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            color: 'white',
            overflow: 'hidden',
          }}
        >
          <Form
            name="login"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            style={{ maxWidth: 1000 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            autoComplete="off"
          >
            <Form.Item<LoginType>
              label={<label style={{ color: 'white' }}>Почта</label>}
              name="publicKey"
              rules={[
                { required: true, message: 'Please input your username!' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item<LoginType>
              label={<label style={{ color: 'white' }}>Пароль</label>}
              name="privateKey"
              rules={[
                { required: true, message: 'Please input your password!' },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
              <Button type="default" htmlType="submit">
                Войти
              </Button>
            </Form.Item>
          </Form>
        </div>
      ) : (
        <>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              gap: '36px',
            }}
          >
            <h1>Админ панель</h1>
            <div>
              <Button type="primary" onClick={logout}>
                Выйти
              </Button>
            </div>
          </div>
          <div style={{ display: 'flex', gap: '24px' }}>
            <Card title="Ссылки" size="default" style={{ width: '1440px' }}>
              <Card title={'Создать'} size="small">
                <Form
                  name="link"
                  onFinish={async (values) => {
                    await createLink({
                      forOrganizationId: values.forOrganizationId.toString(),
                      isMultiplyResponse: isMultiplyChecked,
                      managerId: values.managerId,
                      verticalId: values.verticalId,
                    });
                  }}
                  // onFinishFailed={onFinishFailed}
                  autoComplete="off"
                  style={{
                    display: 'flex',
                    gap: '10px',
                    width: '95%',
                    justifyContent: 'space-between',
                  }}
                >
                  <Form.Item<FieldType>
                    label="Организация"
                    name="forOrganizationId"
                    rules={[
                      { required: true, message: 'Поле необходимо заполнить!' },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '200px' }}
                      onChange={(value, option) => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        //@ts-ignore
                        setManagers(option.managers);
                      }}
                      options={organizations?.map((organization) => {
                        return {
                          value: organization.id,
                          label: organization.title,
                          managers: organization.managers,
                        };
                      })}
                      filterOption={(input, option) =>
                        (option?.label ?? '').includes(input)
                      }
                    />
                  </Form.Item>
                  <Form.Item<FieldType>
                    label="Менеджер"
                    name="managerId"
                    rules={[
                      { required: true, message: 'Поле необходимо заполнить!' },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '200px' }}
                      options={managers?.map((manager) => {
                        return {
                          value: manager.id,
                          label: `${manager.surname} ${manager.name}`,
                        }; // Assuming manager has a 'name' property
                      })}
                      filterOption={(input, option) =>
                        (option?.label ?? '')?.includes(input)
                      }
                    />
                  </Form.Item>
                  <Form.Item<FieldType>
                    label="Вертикаль"
                    name="verticalId"
                    rules={[
                      { required: true, message: 'Поле необходимо заполнить!' },
                    ]}
                  >
                    <Select
                      showSearch
                      style={{ width: '200px' }}
                      options={verticals?.map((vertical) => {
                        return {
                          value: vertical.id,
                          label: vertical.title,
                        };
                      })}
                      filterOption={(input, option) =>
                        (option?.label ?? '').includes(input)
                      }
                    />
                  </Form.Item>
                  <Form.Item<FieldType>
                    label="Множественный опрос"
                    name="isMultiplyResponse"
                  >
                    <Checkbox
                      checked={isMultiplyChecked}
                      onClick={() => setIsMultiplyChecked((prev) => !prev)}
                    />
                  </Form.Item>
                  <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                    <Button type="primary" htmlType="submit">
                      Создать
                    </Button>
                  </Form.Item>
                </Form>
              </Card>
              <LinksTable
                links={
                  links?.map((link) => {
                    return {
                      url: `https://nps.artics.ru/${link.id}`,
                      key: link.id,
                      ...link,
                    };
                  }) as Item[]
                }
                verticals={verticals}
                organizations={organizations}
                deleteLink={deleteLink}
                updateLink={updateLink}
                createQuestion={createQuestion}
                deleteQuestion={deleteQuestion}
                error={error}
                isEditable={true}
                setError={setError}
                setAuth={setAuth}
              />
            </Card>
          </div>
          <div style={{ display: 'flex', gap: '24px' }}>
            <Card title="Вопросы" size="default" style={{ width: '1440px' }}>
              {me?.role.role !== USER_ROLE.MANAGER && (
                <Card title={'Создать'} size="small">
                  <Form
                    name="question"
                    onFinish={async (values: TQuestion) => {
                      await createQuestion({
                        text: values.text,
                        responseType: values.responseType,
                      });
                    }}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{ display: 'flex', gap: '10px' }}
                  >
                    <Form.Item<TQuestion>
                      label="Вопрос"
                      name="text"
                      rules={[
                        {
                          required: true,
                          message: 'Поле необходимо заполнить!',
                        },
                      ]}
                    >
                      <TextArea />
                    </Form.Item>
                    <Form.Item<TQuestion>
                      label="Тип вопроса"
                      name="responseType"
                      rules={[
                        {
                          required: true,
                          message: 'Поле необходимо заполнить!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '200px' }}
                        options={[
                          {
                            value: 'ESTIMATE',
                            label: 'Оценка',
                          },
                          {
                            value: 'COMMENT',
                            label: 'Комментарий',
                          },
                        ]}
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                      />
                    </Form.Item>
                    {/*<Form.Item<TQuestion>*/}
                    {/*	label="Вертикали"*/}
                    {/*	name="verticals"*/}
                    {/*	rules={[{required: true, message: 'Поле необходимо заполнить!'}]}*/}
                    {/*>*/}
                    {/*	<Select*/}
                    {/*		mode={'multiple'}*/}
                    {/*		showSearch*/}
                    {/*		style={{width: "200px"}}*/}
                    {/*		options={*/}
                    {/*			verticals?.map((vertical) => {*/}
                    {/*					return {*/}
                    {/*						value: vertical.id,*/}
                    {/*						label: vertical.title,*/}
                    {/*					}*/}
                    {/*				}*/}
                    {/*			)*/}
                    {/*		}*/}
                    {/*		filterOption={(input, option) => (option?.label ?? '').includes(input)}*/}
                    {/*	/>*/}
                    {/*</Form.Item>*/}
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button type="primary" htmlType="submit">
                        Создать
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
              )}
              <QuestionsTable
                deleteQuestion={deleteQuestion}
                questions={questions}
                verticals={verticals}
                insertQuestionToVertical={insertQuestionToVertical}
                removeQuestionToVertical={removeQuestionToVertical}
                updateQuestion={updateQuestion}
                getQuestions={getQuestions}
                isEditable={me?.role.role !== USER_ROLE.MANAGER}
              />
            </Card>
          </div>
          {me?.role.role === USER_ROLE.ADMIN && (
            <div style={{ display: 'flex', gap: '24px' }}>
              <Card
                title="Пользователи "
                size="default"
                style={{ width: '1440px' }}
              >
                <Card title={'Создать'} size="small">
                  <Form
                    name="user"
                    onFinish={async (values: TUser) => {
                      await createUserRole(values);
                    }}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                    style={{ display: 'flex', gap: '10px' }}
                  >
                    <Form.Item<TUser>
                      label="Email"
                      name="publicKey"
                      rules={[
                        {
                          required: true,
                          message: 'Поле необходимо заполнить!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '200px' }}
                        options={userEmails?.map((user) => {
                          return {
                            value: user.email,
                            label: user.email,
                          };
                        })}
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                      />
                    </Form.Item>
                    <Form.Item<TUser>
                      label="Роль"
                      name="role"
                      rules={[
                        {
                          required: true,
                          message: 'Поле необходимо заполнить!',
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        style={{ width: '200px' }}
                        options={[
                          {
                            value: 'MANAGER',
                            label: 'MANAGER',
                          },
                          {
                            value: 'DIRECTOR',
                            label: 'DIRECTOR',
                          },
                        ]}
                        filterOption={(input, option) =>
                          (option?.label ?? '').includes(input)
                        }
                      />
                    </Form.Item>
                    <Form.Item<TUser>
                      label="Пароль"
                      name="privateKey"
                      rules={[
                        { required: true, message: 'Введите пароль!' },
                        {
                          validator: (_, value) => {
                            if (
                              !value ||
                              /^(?=.*[A-Za-z])(?=.*\d).+$/.test(value)
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              'Пароль должен содержать как минимум одну букву и одну цифру!',
                            );
                          },
                        },
                      ]}
                    >
                      <Input style={{ width: '200px' }} />
                    </Form.Item>
                    <Form.Item<TUser>
                      label="Повторите пароль"
                      name="rePrivateKey"
                      dependencies={['privateKey']}
                      rules={[
                        { required: true, message: 'Повторите пароль!' },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (
                              !value ||
                              getFieldValue('privateKey') === value
                            ) {
                              return Promise.resolve();
                            }
                            return Promise.reject('Пароли не совпадают!');
                          },
                        }),
                      ]}
                    >
                      <Input style={{ width: '200px' }} />
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 8, span: 16 }}>
                      <Button type="primary" htmlType="submit">
                        Создать
                      </Button>
                    </Form.Item>
                  </Form>
                </Card>
                <UsersTable
                  users={users}
                  deleteUserRole={deleteUserRole}
                  isEditable={true}
                />
              </Card>
            </div>
          )}
        </>
      )}
    </div>
  );
};
