import React, { createElement, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Form,
  Input,
  message,
  Modal,
  Select,
  Table,
  Typography,
} from 'antd';
import {
  ICreateQuestion,
  ILink,
  IManager,
  IOrganization,
  IUpdateLink,
  IVertical,
} from '../../hooks/useLink';
import { CopyOutlined } from '@ant-design/icons';

enum InputType {
  IsMultiplyResponse = 'isMultiplyResponse',
  ForOrganization = 'forOrganization',
  Manager = 'manager',
  Vertical = 'vertical',
}

export interface Item extends ILink {
  key: string;
  url: string;
}
interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: InputType;
  record: Item;
  index: number;
  children: React.ReactNode;
}

interface ILinksProps {
  links?: Item[];
  verticals?: IVertical[];
  organizations?: IOrganization[];
  deleteLink: (linkId: string) => Promise<boolean>;
  updateLink: (linkId: string, link: IUpdateLink) => void;
  createQuestion: (question: ICreateQuestion) => Promise<void>;
  deleteQuestion: (questionId: string) => Promise<boolean>;
  error?: any;
  setError: (error: any) => void;
  setAuth: (auth: boolean) => void;
  isEditable: boolean;
}
export const LinksTable = ({
  links,
  organizations,
  verticals,
  deleteLink,
  updateLink,
  error,
  isEditable,
  setError,
}: ILinksProps) => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [isMultiplyChecked, setIsMultiplyChecked] = useState<
    boolean | undefined
  >(undefined);
  const [organizationId, setOrganizationId] = useState<string | undefined>(
    undefined,
  );
  const [managerId, setManagerId] = useState<string | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [managers, setManagers] = useState<IManager[] | undefined>(undefined);
  // const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [verticalId, setVerticalId] = useState<number | undefined>(undefined);
  const [record, setRecord] = useState<Item | undefined>();

  // const showDrawer = (record: Item) => {
  // 	setIsDrawerOpen(true);
  // 	setRecord(record);
  // };
  //
  // const closeDrawer = () => {
  // 	setIsDrawerOpen(false);
  // 	setRecord(undefined)
  // };

  useEffect(() => {
    if (error) {
      setIsModalOpen(true);
    }
  }, [error]);

  useEffect(() => {
    if (links && record) {
      setRecord(links.filter((link) => link.id === record.id)[0]);
    }
  }, [links]);

  const isEditing = (record: Item) => record.key === editingKey;

  const edit = (record: Partial<Item> & { key: React.Key }) => {
    form.setFieldsValue({
      forOrganization: '',
      isMultiplyResponse: '',
      ...record,
    });
    setIsMultiplyChecked(record.isMultiplyResponse);
    setOrganizationId(record.forOrganizationId);
    setManagerId(record.managerId);
    setManagers(record.forOrganization?.managers);
    setEditingKey(record.key);
    setVerticalId(record.vertical?.id);
  };

  const EditableCell: React.FC<EditableCellProps> = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {
    const inputNode = useMemo(() => {
      switch (inputType) {
        case InputType.IsMultiplyResponse:
          return (
            <Checkbox
              defaultChecked={record.isMultiplyResponse}
              checked={isMultiplyChecked}
              onClick={() => setIsMultiplyChecked((prev) => !prev)}
            />
          );
        case InputType.ForOrganization:
          return (
            <Select
              style={{ width: '200px' }}
              defaultValue={record.forOrganizationId}
              value={organizationId}
              onChange={(v) => {
                setOrganizationId(v);
                setManagerId(undefined);
                setManagers(
                  organizations?.filter(
                    (organization) => organization.id === v,
                  )[0]?.managers,
                );
              }}
              options={organizations?.map((organization) => ({
                value: organization.id,
                label: organization.title,
              }))}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          );
        case InputType.Manager:
          return (
            <Select
              style={{ width: '200px' }}
              defaultValue={managerId}
              value={managerId}
              onChange={(v) => setManagerId(v)}
              options={managers?.map((manager) => ({
                value: manager.id,
                label: `${manager.surname} ${manager.name}`,
              }))}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          );
        case InputType.Vertical:
          return (
            <Select
              style={{ width: '200px' }}
              defaultValue={record.vertical?.id}
              value={verticalId}
              onChange={(v) => {
                setVerticalId(v);
              }}
              options={verticals?.map((vertical) => ({
                value: vertical.id,
                label: vertical.title,
              }))}
              filterOption={(input, option) =>
                (option?.label ?? '').includes(input)
              }
            />
          );
        default:
          return <Input />;
      }
    }, [
      inputType,
      record,
      isMultiplyChecked,
      organizationId,
      managers,
      managerId,
    ]);

    return <td {...restProps}>{editing ? inputNode : children}</td>;
  };

  const cancel = () => {
    setOrganizationId(undefined);
    setEditingKey('');
    setIsMultiplyChecked(undefined);
    setManagerId(undefined);
    setVerticalId(undefined);
  };

  const save = async (id: string) => {
    try {
      await updateLink(id, {
        forOrganizationId: organizationId!,
        isMultiplyResponse: isMultiplyChecked!,
        managerId: managerId!,
        verticalId: verticalId!,
      });
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'Организация',
      dataIndex: 'forOrganization',
      width: '25%',
      editable: true,
      render: (_: any, record: Item) => {
        return (
          <Typography.Text>{record.forOrganization.title}</Typography.Text>
        );
      },
    },
    {
      title: 'Менеджер',
      dataIndex: 'manager',
      width: '20%',
      editable: true,
      render: (_: any, record: Item) => {
        return (
          <Typography.Text>
            {record.managerId &&
              `${record.manager?.surname} ${record.manager?.name}`}
          </Typography.Text>
        );
      },
    },
    {
      title: 'Вертикаль',
      dataIndex: 'vertical',
      width: '20%',
      editable: true,
      render: (_: any, record: Item) => {
        return <Typography.Text>{record.vertical?.title}</Typography.Text>;
      },
    },
    {
      title: 'Множественный опрос',
      dataIndex: 'isMultiplyResponse',
      width: '5%',
      editable: true,
      render: (_: any, record: Item) => {
        return <Checkbox checked={record.isMultiplyResponse}></Checkbox>;
      },
    },
    {
      title: 'Пройден ли опрос',
      dataIndex: 'isEstimated',
      width: '5%',
      render: (_: any, record: Item) => {
        return <Checkbox checked={!!record.estimates?.length}></Checkbox>;
      },
    },
    {
      title: 'ссылка',
      dataIndex: 'url',
      width: '40%',
      editable: false,
      render: (_: any, record: Item) => {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography.Text>{record.url}</Typography.Text>
            <Button
              icon={createElement(CopyOutlined)}
              onClick={async () => {
                await navigator.clipboard.writeText(record.url);
                message.success('Ссылка скопирована в буфер обмена');
              }}
            />
          </div>
        );
      },
    },
    // {
    // 	title: 'Вопросы',
    // 	dataIndex: 'questions',
    // 	width: '40%',
    // 	editable: false,
    // 	render: (_: any, record: Item) => {
    // 		return (
    // 			<div
    // 				style={{
    // 					display: "flex",
    // 					justifyContent: "space-between",
    //
    // 				}}
    // 			>
    // 				<Typography.Text>
    // 					{record.questions.length}
    // 				</Typography.Text>
    // 				<Button
    // 					icon={createElement(EditOutlined)}
    // 					onClick={() => showDrawer(record)}
    // 				/>
    // 			</div>
    // 		);
    // 	}
    // },
  ];

  isEditable &&
    columns.push({
      title: 'Операции',
      dataIndex: 'operation',
      width: '10%',
      editable: false,
      render: (_: any, record: Item) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{ marginRight: 8 }}
            >
              Сохранить
            </Typography.Link>
            <Typography.Link onClick={cancel}>
              <a>Отменить</a>
            </Typography.Link>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              style={{ marginRight: 8 }}
            >
              Изменить
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => deleteLink(record.id)}
            >
              Удалить
            </Typography.Link>
          </>
        );
      },
    });

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: Item) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  return (
    <Form form={form} component={false}>
      <Table
        components={{
          body: {
            cell: EditableCell,
          },
        }}
        bordered
        dataSource={links}
        columns={mergedColumns}
        rowClassName="editable-row"
        pagination={{
          pageSize: 5,
          style: {
            backgroundColor: 'white',
            margin: '0',
          },
        }}
      />
      <Modal
        open={isModalOpen}
        onOk={() => {
          setIsModalOpen(false);
          setError(undefined);
          // setAuth(false);
        }}
        onCancel={() => {
          setIsModalOpen(false);
          setError(undefined);
          // setAuth(false);
        }}
        cancelButtonProps={{
          style: { display: 'none' },
        }}
        okText={'ОК'}
      >
        <div>
          <Typography.Text>
            {error?.response?.data?.message
              ? error.response.data.message
              : 'Ошибка при выполнении действия'}
          </Typography.Text>
        </div>
      </Modal>
    </Form>
  );
};
