import { IEstimate } from '../../hooks/useLink';

export interface IDefaultQuestionProps {
  generateScoreButtons: () => React.ReactNode;
  estimate: number | undefined;
  setComment: (
    value: string | ((prevVar: string | undefined) => string) | undefined,
  ) => void;
  comment: string | undefined;
  linkId?: string;
  createEstimate: (linkId: string, estimate: IEstimate) => void;
  goNext: () => void;
}
export const DefaultQuestion = ({
  generateScoreButtons,
  comment,
  setComment,
  estimate,
  linkId,
  createEstimate,
  goNext,
}: IDefaultQuestionProps) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <h1 style={{ width: '80%', alignSelf: 'center', marginBottom: '100px' }}>
        Пожалуйста, уделите несколько минут обратной связи о нашей работе. Ваше
        мнение действительно ценно и поможет нам стать лучше!
      </h1>
      {/*<div style={{display: "flex", justifyContent: "space-between", marginBottom: "100px", width: "100%"}}>*/}
      {/*	{generateScoreButtons()}*/}
      {/*</div>*/}
      {/*<textarea*/}
      {/*	style={{*/}
      {/*		width: "48.8%",*/}
      {/*		height: "200px",*/}
      {/*		fontSize: "16px",*/}
      {/*		padding: "10px",*/}
      {/*		marginBottom: "20px",*/}
      {/*		border: "1px solid black",*/}
      {/*	}}*/}
      {/*	value={comment}*/}
      {/*	onChange={(e) => {*/}
      {/*		setComment(e.target.value)*/}
      {/*	}}*/}
      {/*	placeholder="Почему вы поставили именно такую оценку"*/}
      {/*/>*/}
      <button
        style={{ height: '100px', width: '50%', fontSize: '30px' }}
        onClick={goNext}
      >
        Начать опрос
      </button>
    </div>
  );
};
