import { IEstimate, useLink } from '../../hooks/useLink';
import { useParams } from 'react-router-dom';
import { useRef, useState } from 'react';
import { Carousel } from '../Carousel/Carousel';
import { DefaultQuestion } from '../DefaultQuestion/DefaultQuestion';
import { QUESTION_TYPE } from '../Admin/Admin';

export const Poll = () => {
  const { linkId } = useParams();
  const { link, createEstimates, createEstimate, error } = useLink(linkId);
  const [estimate, setEstimate] = useState<number | undefined>();
  const [questionComment, setQuestionComment] = useState<string | undefined>();
  const [clickedButton, setClickedButton] = useState<number | null>(null);
  const [comment, setComment] = useState<string | undefined>(undefined);
  const [responses, setResponses] = useState<IEstimate[]>([]);
  const swiperRef = useRef(null);
  const goNext = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    if (swiperRef.current && swiperRef.current.swiper) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      swiperRef.current.swiper.slideNext();
      setQuestionComment(undefined);
      setClickedButton(null);
      setEstimate(undefined);
    }
  };

  const handleScoreClick = (score: number) => {
    if (estimate === score) {
      setEstimate(undefined);
      setClickedButton(null);
    } else {
      setEstimate(score);
      setClickedButton(score);
    }
  };

  const generateScoreButtons = () => {
    const buttons = [];
    for (let score = 1; score <= 10; score++) {
      const buttonStyle = {
        borderRadius: '100%',
        backgroundColor: score === clickedButton ? '#818181' : 'white',
        color: 'black',
        border: '1px solid black',
        width: '80px',
        height: '80px',
        fontSize: '30px',
      };
      buttons.push(
        <button
          style={buttonStyle}
          key={score}
          onClick={() => handleScoreClick(score)}
        >
          {score}
        </button>,
      );
    }
    return buttons;
  };
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {!error ? (
        link?.vertical?.questionToVerticals &&
        link?.vertical?.questionToVerticals.length > 0 ? (
          <Carousel swiperRef={swiperRef}>
            <DefaultQuestion
              generateScoreButtons={generateScoreButtons}
              estimate={estimate}
              setComment={setComment}
              comment={comment}
              linkId={link?.id}
              goNext={goNext}
              createEstimate={createEstimate}
            />
            {link?.vertical?.questionToVerticals?.map(
              (questionToVerticals, index) => (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <h1
                    style={{
                      width: '80%',
                      alignSelf: 'center',
                      marginBottom: '100px',
                    }}
                  >
                    {questionToVerticals.question.text}
                  </h1>
                  {questionToVerticals.question.responseType ===
                    QUESTION_TYPE.ESTIMATE && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: '100px',
                        width: '100%',
                      }}
                    >
                      {generateScoreButtons()}
                    </div>
                  )}
                  <textarea
                    style={{
                      width: '48.8%',
                      height: '200px',
                      fontSize: '16px',
                      padding: '10px',
                      marginBottom: '20px',
                      border: '1px solid black',
                    }}
                    value={comment}
                    onChange={(e) => {
                      setQuestionComment(e.target.value);
                    }}
                    placeholder="Почему вы поставили именно такую оценку"
                  />
                  <button
                    style={{ height: '100px', width: '50%', fontSize: '30px' }}
                    disabled={!(estimate || questionComment)}
                    onClick={async () => {
                      if ((estimate || questionComment) && linkId) {
                        setResponses((prevState) => [
                          ...prevState,
                          {
                            estimate: estimate!,
                            linkId,
                            questionId: questionToVerticals.question.id,
                            comment: comment || null,
                          },
                        ]);
                        goNext();
                        if (
                          index + 1 ===
                          link?.vertical?.questionToVerticals?.length
                        ) {
                          await createEstimates([
                            ...responses,
                            {
                              estimate: estimate!,
                              linkId,
                              questionId: questionToVerticals.question.id,
                              comment: comment || null,
                            },
                          ]);
                          window.location.href = `/success`;
                        }
                      }
                    }}
                  >
                    Подтвердить
                  </button>
                </div>
              ),
            )}
            {/*<DefaultQuestion*/}
            {/*	generateScoreButtons={generateScoreButtons}*/}
            {/*	estimate={estimate}*/}
            {/*	setComment={setComment}*/}
            {/*	comment={comment}*/}
            {/*	linkId={link.id}*/}
            {/*	createEstimate={createEstimate}*/}
            {/*	/>*/}
          </Carousel>
        ) : (
          <DefaultQuestion
            generateScoreButtons={generateScoreButtons}
            estimate={estimate}
            setComment={setComment}
            comment={comment}
            linkId={link?.id}
            createEstimate={createEstimate}
            goNext={goNext}
          />
        )
      ) : (
        <h1>В этом месяце вы уже проголосовали</h1>
      )}
    </div>
  );
};
